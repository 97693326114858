import React from 'react';
import logo from './labpipe-logo-light.png';
import './App.css';
import {Button, Col, Layout, Result, Row} from "antd";
import {ReadOutlined, GithubOutlined, CopyrightOutlined} from '@ant-design/icons';

const {Content, Footer} = Layout;

var logoNode = React.createElement('img', {src: logo, width: "50%"});

function App() {
    return (
        <Layout className={"full-vh"}>
            <Content>
                        <Result className={"mt-10-p"}
                            icon={logoNode}
                            title="LabPipe"
                            subTitle="Consistent, controlled approach for handling metadata and experimental data collection, collation and linkage"
                            extra={[<Button type={"primary"} key={"docs"} target={"_blank"}
                                            href={"https://docs.labpipe.org"}><ReadOutlined/>Documentation</Button>,
                                <Button key={"github"} target={"_blank"}
                                        href={"https://github.com/rcfgroup/labpipe"}><GithubOutlined/>GitHub</Button>]}
                        />
            </Content>
            <Footer>
                <Row justify={"center"}>
                    <Col>
                        <Button type="link" target={"_blank"} href={"https://rcfgroup.github.io"}><CopyrightOutlined/>RCF
                            Group</Button>
                    </Col>
                </Row>
            </Footer>
        </Layout>
    );
}

export default App;
